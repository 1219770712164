<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<div class="img"> <img src="@/assets/login/flag.png"></div>
				<div class="login_title" @click="backClick">{{$t('inforOrder')}}</div>
				<div class="front_img">
					<img src="@/assets/home_icons/front.png">
				</div>
				<div class="sub_title">{{$t('order.orderDetail')}}</div>
			</div>
		</div>
		<div class="gap">

		</div>
		<div class="order_sn">
			<div class="order_sn_top">
				{{$t('order.orderNo')}}:{{orderDetail.order_sn}}
			</div>
			<div class="order_sn_bottom">
				<div class="order_sn_bottom_left">
					<div class="status_img">
						<img v-if="orderDetail.status==0" src="../../../../assets/icons/status_to.png">
						<img v-if="orderDetail.status==-1" src="../../../../assets/icons/status_cancel.png">
						<img v-if="orderDetail.status==1" src="../../../../assets/icons/status_finish.png">
					</div>
					<div class="status_text">
						<template v-if="orderDetail.status==0">{{$t('order.obligation')}}</template>
						<template v-if="orderDetail.status==-1">{{$t('order.hasCancel')}}</template>
						<template v-if="orderDetail.status==1">{{$t('order.finish')}}</template>
					</div>
				</div>
				<div class="order_sn_bottom_right">
					<template v-if="orderDetail.status==0">
						<div class="btn btn_grey" @click="cancelOrderClick()">
							{{$t('order.cancelOrder')}}
						</div>
						<div class="btn btn_red" @click="toPayOrderClick()">
							{{$t('order.toPay')}}
						</div>
					</template>
					<template v-else>
						<div class="btn btn_grey" @click="deleteOrderClick">
							{{$t('order.deleteOrder')}}
						</div>
					</template>
				</div>
			</div>
		</div>
		<div class="gap">
		</div>
		<div class="step">
			<div class="step_item">
				<div class="step_item_top">
					<img src="../../../../assets/icons/step1.png">
				</div>
				<div class="step_item_middle">
					<img src="../../../../assets/icons/spot.png">
				</div>
				<div class="step_item_bottom" :style="{color:orderDetail.status==-1?'#E60012':'#33333'}">
					{{$t('submitOrder')}}
				</div>
			</div>
			<div class="step_item">
				<div class="step_item_top">
					<img src="../../../../assets/icons/step2.png">
				</div>
				<div class="step_item_middle">
					<img src="../../../../assets/icons/spot.png">
				</div>
				<div class="step_item_bottom" :style="{color:orderDetail.status==0?'#E60012':'#33333'}">
					{{$t('payOrder')}}
				</div>
			</div>
			<div class="step_item">
				<div class="step_item_top">
					<img src="../../../../assets/icons/step3.png">
				</div>
				<div class="step_item_middle">
					<img src="../../../../assets/icons/spot.png">
				</div>
				<div class="step_item_bottom" :style="{color:orderDetail.status==1?'#E60012':'#33333'}">
					{{$t('finishOrder')}}
				</div>
			</div>
			<div class="line"></div>
		</div>
		<div class="gap">
		</div>
		<div class="order_detail">
			<div class="table">
				<el-table :data="orderItem" style="width: 100%" :header-cell-style="{background:'#F6F7F9',}" border>
					<el-table-column prop="date" :label="$t('releaseContent')" width="180" align="center">
						<template slot-scope="scope">
							<div class="infor">
								<div class="infor_img">
									<img :src="scope.row.thumb_url">
								</div>
								<div class="infor_title">
									{{scope.row.title}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="payable_money" :label="$t('money')" align="center">
					</el-table-column>
					<el-table-column :label="$t('state')" align="center">
						<template slot-scope="scope">
							<!-- 状态;-1:取消已失效;0:待付款;1:已完成 -->
							<div class="status">
								<span class="blue" v-if="scope.row.status==-1">{{$t('order.hasCancel')}}</span>
								<span class="gray" v-if="scope.row.status==0">{{$t('order.obligation')}}</span>
								<span class="red" v-if="scope.row.status==1">{{$t('order.finish')}}</span>
							</div>
						</template>
					</el-table-column>

					<el-table-column :label="$t('operation')" align="center">
						<template slot-scope="scope">
							<div class="operate">
								<el-popconfirm :title="$t('my.deleteAllContent')" @confirm="deleteOrderClick()"
									v-if="scope.row.status!=0">
									<span class="gray" slot="reference">{{$t('delete')}}</span>
								</el-popconfirm>
								<span class="gray" @click="cancelOrderClick()"
									v-if="scope.row.status==0">{{$t('order.cancelOrder')}}</span>
								<span class="blue" @click="toPayOrderClick()"
									v-if="scope.row.status==0">{{$t('order.toPay')}}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="order_infor">
				<div class="order_infor_title">
					{{$t('order.orderInfor')}}
				</div>
				<div class="order_infor_content">
					<div class="order_infor_content_left">
						<div>{{$t('order.orderNo')}}:{{orderDetail.order_sn}}</div>
						<div>{{$t('order.orderTime')}}:{{orderDetail.create_time}}</div>
					</div>
					<div class="order_infor_content_right">
						<div>{{$t('order.messageFee')}}:{{orderDetail.payable_money}}</div>
						<!-- <div>{{$t('order.locationHighlight')}}:</div>
						<div>{{$t('order.locationRecommend')}}:</div> -->
						<div class="need_pay">
							<div class="need_pay_left">
								{{$t('order.accountPayable')}}：
							</div>
							<div class="need_pay_right">
								{{orderDetail.payable_money}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				order_sn: '', //订单号
				orderDetail: '',
				orderItem: [], //


				messageList: [], //信息列表
				total: 8, //总条数
				currentPage: 1, //当前页数
				pageSize: 8, //每页的条数
				pageSizes: [8, 10, 20, 30, 40, 50, 100],
			}
		},
		mounted() {
			this.order_sn = this.$route.query.order_sn;
			this.getAll();
		},
		methods: {
			getAll() {
				this.$http.orderDetail({
					order_sn: this.order_sn,
				}).then(res => {
					if (res.code == 1) {
						this.orderDetail = res.data;
						this.orderItem = [{
							id: this.orderDetail.id,
							order_sn: this.orderDetail.order_sn,
							title: this.orderDetail.information_info.title,
							thumb_url: this.orderDetail.information_info.thumb_url[0].path,
							payable_money: this.orderDetail.payable_money,
							status: this.orderDetail.status,
						}];

						// this.thumb_url = this.orderDetail.information_info.thumb_url[0].path;


						// console.log(this.thumb_url, "oooo");
						// let nowTime = getCurrentZoneTime(-5); //获取西五区当前的时间戳
						// let startTime = new Date(this.orderDetail.create_time).getTime();
						// let diffTime = nowTime - startTime;
						// this.countDownTime = parseInt((this.restTime - diffTime) / 1000);
						console.log(this.countDownTime);
					} else {
						this.$message.error(res.msg);
					}
				})
			},

			backClick() {
				this.$router.back();
			},

			// 取消订单
			cancelOrderClick() {
				this.$http.cancelOrder({
					order_sn: this.orderDetail.order_sn,
					type: 1,
				}).then(res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.getAll();
					} else {
						this.$message.error(res.msg);
					}
				})

			},
			// 去支付
			toPayOrderClick() {
				this.$http.getPayPal({
					order_sn: this.orderDetail.order_sn,
				}).then(res => {
					if (res.code == 1) {
						let payUrl = res.data;
						window.open(payUrl);
					}
				})
			},
			// 删除订单
			deleteOrderClick() {
				this.$http.orderDel({
					order_sn: this.orderDetail.order_sn,
				}).then(res => {
					if (res.code == 1) {
						this.$message.info(res.msg);
						setTimeout(() => {
							this.$router.push({
								path: '/home/person/inforOrder',
								query: {
									curCenterIndex: 3,
								}
							})
						}, 1000)
					} else {
						this.$message.info(res.msg);
					}
				})
			},

		}
	}
</script>
<style>
	/* # background-color为悬停行得背景颜色 ,color为悬停行得字体颜色，其他样式自行添加 */
	.el-table tbody tr:hover>td {
		background-color: #FFFFFF !important;
	}
</style>
<style scoped="" lang="less">
	.step {
		padding: 27px 0 37px;
		display: flex;
		justify-content: space-around;
		background-color: #FFFFFF;
		position: relative;

		.step_item {
			width: 160px;

			.step_item_top {
				img {
					width: 28px;
					height: 28px;
				}
			}

			.step_item_middle {
				position: relative;
				z-index: 400;

				img {
					width: 12px;
					height: 12px;
				}
			}

			.step_item_bottom {
				padding-top: 16px;
				font-size: 14px;
			}
		}

		.line {
			position: absolute;
			top: 67px;
			width: 628px;
			height: 1px;
			background-color: #BFBFBF;
		}

	}

	.person_infor {
		width: 926px;
	}


	.login_top {
		display: flex;
		align-items: center;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;


		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;

			&:hover {
				cursor: pointer;
			}
		}

		.front_img {
			display: flex;
			align-items: center;
			margin: 0 12px;

			img {
				width: 8px;
				height: 14px;
			}
		}
	}

	.order_sn {
		border: 1px solid #E60012;

		.order_sn_top {
			padding-left: 48px;
			height: 48px;
			text-align: left;
			line-height: 48px;
			background: rgba(230, 0, 18, 0.09);
			font-size: 14px;
		}

		.order_sn_bottom {
			padding: 24px 32px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.order_sn_bottom_left {
				display: flex;
				align-items: center;

				.status_img {
					img {
						width: 113px;
						height: 80px;
					}
				}

				.status_text {
					margin-left: 24px;
					color: #333333;
					font-size: 18px;
					font-weight: bold;
				}
			}

			.order_sn_bottom_right {
				display: flex;


				.btn {
					display: inline-block;
					box-sizing: border-box;
					padding: 8px 24px;
					min-width: 104px;
					border-radius: 16px;
					font-size: 14px;
					cursor: pointer;
				}

				.btn_red {
					margin-left: 24px;
					background-color: #E60012;
					color: #FFFFFF;
				}

				.btn_grey {
					background-color: #F6F7F9;
					color: #333333;
				}
			}
		}
	}

	.gap {
		height: 20px;
		background-color: #F6F7F9;
	}

	.order_detail {
		background-color: #FFFFFF;
		padding: 24px;
	}

	.order_infor {
		.order_infor_title {
			font-weight: bold;
			font-size: 14px;
			padding: 32px 0 16px;
			border-bottom: 1px solid #EEEEEE;
			text-align: left;
		}

		.order_infor_content {
			padding-top: 24px;
			display: flex;
			justify-content: space-between;

			.order_infor_content_left {
				text-align: left;

				&>div {
					font-size: 14px;
					margin-bottom: 16px;
				}
			}

			.order_infor_content_right {
				text-align: right;

				&>div {
					font-size: 14px;
					margin-bottom: 16px;
				}

				.need_pay {
					display: flex;
					align-items: center;
					font-size: 16px;
					font-weight: bold;

					.need_pay_left {}

					.need_pay_right {
						color: #E60012;
					}
				}
			}
		}
	}

	.table {

		.infor {
			display: flex;
			justify-content: center;
			align-items: center;

			.infor_img {
				img {
					width: 48px;
					height: 48px;
				}
			}

			.infor_title {
				margin-left: 8px;
				width: 132;
				height: 45px;
				color: #333333;
				font-weight: 500;
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				line-clamp: 2;
				-webkit-box-orient: vertical;
				word-break: break-all;
				word-wrap: break-word;
			}
		}

		.status {
			font-size: 12px;

			&>span:hover {
				cursor: pointer;
			}

			.red {
				color: #E60012;
			}

			.blue {
				color: #4177DD;
			}

			.gray {
				color: #999999;
			}

		}

		.operate {
			span {
				font-size: 12px;
				display: inline-block;

				&:hover {
					cursor: pointer;
				}
			}

			.blue {
				margin-left: 8px;
				color: #4177DD;
			}

			.gray {
				color: #999999;
			}
		}
	}
</style>
